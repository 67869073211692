import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CFAPIService } from 'src/app/services/api.service';
import { FormGroupComponent } from 'src/app/shared/pages/form-group/form-group.component';
import { AuthService } from 'src/app/services/auth.service';
import { LivecoAPIService } from 'src/app/services/liveco-api.service';
import { TranslateService } from '@ngx-translate/core';

// @ts-ignore
// const api = require('@modules/cf-api.js').api;;
// const api_async = require('@modules/cf-api.js').api_async;

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ResetPasswordComponent extends FormGroupComponent<any> implements OnInit {

    userId: string;
    isFirst: number = 0;
    // valid: Observable<boolean> = new Observable<boolean>();
    loadingByDefault = false;

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected s_alert: AlertService,
        protected cd: ChangeDetectorRef,
        protected s_loader: LoaderService,
        protected dialog: MatDialog,
        protected s_auth: AuthService,
        protected liveco: LivecoAPIService,
        protected translate: TranslateService
    ) {
        super(route, router, s_alert, s_loader, s_auth, translate);
        this.route.params.subscribe(params => {
            if (params && params['id']) {
                this.userId = params['id'];
            }
            if (params && params['isFirst']) {
                this.isFirst = params['isFirst'];
            }
        });
    }

    submitForm(): void {

        this.form.markAllAsTouched();

        if (this.form.valid) {
            this.s_loader.loading = true;
            let form = this.form.getRawValue();
            console.log('Form : ', form);
            this.form.markAsPristine();
            this.updateUserPassword(form, this.userId);
        }
    }

    async updateUserPassword(formPassword: any, id: string) {
        try {
            const res = await this.liveco.create('/update-user-password', { currentUser: this.currentUser, userId: id, currentPassword: formPassword.currentPassword, newPassword: formPassword.newPassword });
            console.log("res updateUser", res);
            if (res) {
                this.s_alert.success('USER.PASSWORD.UPDATE_SUCCESS');
                if (this.isFirst) {
                    this.router.navigate(['']);
                }
            }
        } catch (error: any) {
            console.error('Error updateUserPassword', error);
            this.s_alert.error(error.message);
            throw error;
        } finally {
            this.s_loader.loading = false;
        }

    }
}